import React, { ReactNode, useEffect } from 'react';
import {
  AccordionItem as SherpaAccordionItem,
  AccordionItemButton as SherpaAccordionItemButton,
  AccordionItemButtonIcon as SherpaAccordionItemButtonIcon,
  AccordionItemButtonTitle as SherpaAccordionItemButtonTitle,
  AccordionItemContent as SherpaAccordionItemContent,
} from '../../../../deps/@bshgroup/component-accordion';
import { ChevronDown as SherpaChevronDown } from '../../../../deps/@bshgroup/component-icons';
import { domAnimation, LazyMotion } from 'framer-motion';
import {Icon} from "../../Icon/Icon";
import {useTheme} from "../../ThemeProvider/ThemeProvider";
import { useAccordionControl } from '../Accordion/AccordionControlContext';
import { useTranslation } from '../../../contexts/TranslationContext/TranslationContext';
import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';

export interface FeatureProps {
  /**
   * Element id, required for tracking opened Features
   */
  id: string;
  /**
   * Feature title
   */
  title: ReactNode;
  /**
   * Feature description
   */
  description?: ReactNode;
  /**
   * Raw content additional to text description
   */
  children?: ReactNode;
  /**
   * Feature icon, either string for `Icon` component or custom element
   */
  icon?: ReactNode;
  /**
   * Initial expanded state
   */
  initialExpanded?: boolean;
}

/**
 * The `Feature` displays a title and description to be used inside an `Accordion`.
 */
export function Feature({
  id,
  title,
  description,
  children,
  icon,
  initialExpanded = false,
  ...props
}: FeatureProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof FeatureProps>) {
  const { t } = useTranslation();
  const { isExpanded, toggleExpanded, setExpanded } = useAccordionControl();
  const {tokens} = useTheme();

  useEffect(() => {
    setExpanded(id, initialExpanded);
  }, []);

  return (
    <SherpaAccordionItem
      key={id}
      size="md"
      extended={isExpanded(id)}
      button={config => (
        <SherpaAccordionItemButton
          {...config}
          title={config => (
            <SherpaAccordionItemButtonTitle
              {...config}
              headline={() => (
                <div css={{ display: 'flex', alignItems: 'center' }}>
                  {icon && (
                    <div css={{ width: 54, marginRight: 16 }}>
                      { typeof icon === 'string' ? (
                        <Icon size="xl" value={icon} color={tokens.colors['primary-base-500']} />
                      ) : icon}

                    </div>
                  )}
                  <Heading style="title">{title}</Heading>
                </div>
              )}
            />
          )}
          aria-label={t('DSSF.Components.Core.Feature.ExpandButton')}
          icon={config => <SherpaAccordionItemButtonIcon {...config} as={SherpaChevronDown} />}
          onClick={() => toggleExpanded(id)}
        />
      )}
      content={config => (
        <LazyMotion features={domAnimation}>
          <SherpaAccordionItemContent {...config}>
            <div css={{ marginLeft: icon ? 70 : 0 }}>
              {description && <Text>{description}</Text>}
              {children}
            </div>
          </SherpaAccordionItemContent>
        </LazyMotion>
      )}
      {...(props as any)}
    />
  );
}
