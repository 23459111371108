import { useState, useLayoutEffect } from 'react';

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === '[object Arguments]')) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i['return'] != null) _i['return']();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _nonIterableRest() {
  throw new TypeError('Invalid attempt to destructure non-iterable instance');
}

function useMatchMedia(queries) {
  var defaultValues = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var initialValues = defaultValues.length ? defaultValues : Array(queries.length).fill(false);
  var mediaQueryLists = queries.map(function (q) {
    return window.matchMedia(q);
  });

  var getValue = function getValue() {
    // Return the value for the given queries
    var matchedQueries = mediaQueryLists.map(function (mql) {
      return mql.matches;
    });
    return matchedQueries;
  }; // State and setter for matched value

  var _useState = useState(getValue),
    _useState2 = _slicedToArray(_useState, 2),
    value = _useState2[0],
    setValue = _useState2[1];

  useLayoutEffect(function () {
    // Event listener callback
    // Note: By defining getValue outside of useEffect we ensure that it has ...
    // ... current values of hook args (as this hook only runs on mount/dismount).
    var handler = function handler() {
      return setValue(getValue);
    }; // Set a listener for each media query with above handler as callback.

    mediaQueryLists.forEach(function (mql) {
      return mql.addListener(handler);
    }); // Remove listeners on cleanup

    return function () {
      return mediaQueryLists.forEach(function (mql) {
        return mql.removeListener(handler);
      });
    };
  }, []);
  return typeof window === 'undefined' ? initialValues : value;
}

export default useMatchMedia;
