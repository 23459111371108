import React from 'react';

interface SpacerProps {
  size?: 's' | 'm' | 'l';
}

export function Spacer(props: SpacerProps) {
  let size = props.size !== undefined ? props.size : 'm';
  const sizeMap = {
    s: '0.5rem',
    m: '1rem',
    l: '2rem',
  };
  const style = {
    height: sizeMap[size],
  };

  return <div css={style} />;
}
