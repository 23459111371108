import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import useStateRef from 'react-usestateref';
import rfdc from 'rfdc';

const rfdcClone = rfdc();

export type TouchedState = undefined | 'user' | 'app';

export interface InputHandlers {
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    customValue?: any,
  ) => Promise<void>;
  onFocus: () => Promise<void>;
  onBlur: () => Promise<void>;
  onSubmit: () => Promise<void>;
}

export interface AutocompleteOption {
  displayName: string;
  value: string | { [id: string]: any };
}

export interface FormContextInterface {
  /**
   * Get input handlers for a specific input id to attach to an input element.
   */
  inputHandlers: (id: string) => InputHandlers;
  /**
   * The current values of the form. Keys are flattened ids using dot notation.
   */
  values: { [key: string]: any };
  /**
   * The current unflattened values of the form.
   */
  deepValues: { [key: string]: any };
  /**
   * Set the value of a specific input id.
   */
  setValue: (id: string, value: any, touchedState?: TouchedState) => any;
  /**
   * Set the autocomplete options for a specific input id.
   */
  setAutocomplete: (id: string, options: AutocompleteOption[]) => void;
  /**
   * The current autocomplete options of the form. Keys are flattened ids using dot notation.
   */
  autocompleteOptions: { [key: string]: AutocompleteOption[] };
  /**
   * Validate the form values. If values are not provided, the current form values are used.
   */
  validateValues: (values?: { [key: string]: any }) => Promise<void>;
  /**
   * The current touched states of the form. Keys are flattened ids using dot notation.
   */
  touched: { [key: string]: TouchedState };
  /**
   * The current errors of the form. Keys are flattened ids using dot notation.
   */
  errors: { [key: string]: string };
  /**
   * The id of the currently active input or null if none is active.
   */
  activeInput: string | null;
}

export interface FormContextButtonInterface {
  /**
   * Submit the form
   */
  onClick: () => Promise<void>;
  /**
   * Whether the button is disabled
   */
  disabled: boolean;
  /**
   * Clear the form
   */
  clearForm: () => void;
}

export interface FormContextProps {
  /**
   * Children using the form context.
   */
  children: ReactNode;
  /**
   * Initial values for the form, only read upon first render. Object can be nested but keys may not have dots as it will be flattened with dots separating the key layers.
   */
  initialValues: { [key: string]: any };
  /**
   * Validate the form values. Return an object (or a promise resolving to it) with the same keys as the input values and the error messages as values. Keys can also be already flattened or partially flattened using dot notation.
   */
  validate?: (values: any, form: FormContextInterface) => { [key: string]: any } | Promise<{ [key: string]: any }>;
  /**
   * Callback when the form values change. The activeInputId is the id of the input that triggered the change.
   */
  onChange?: (
    values: any,
    activeInputId: string,
    form: FormContextInterface,
  ) => void | AutocompleteOption[] | Promise<void | AutocompleteOption[]>;
  /**
   * Callback when the form is submitted.
   */
  onSubmit?: (values: any, form: FormContextInterface) => void | Promise<void>;
  /**
   * Callback when the form context is available.
   */
  onFormContext?: (form: FormContextInterface) => void;
  /**
   * If true, the form can be submitted without any input being touched.
   */
  submitUntouched?: boolean;
  /**
   * Only perform validation on submit instead of blur and input
   */
  submitOnlyValidation?: boolean;
}

const FormContextContext = createContext<FormContextInterface>({
  inputHandlers: () => ({
    value: '',
    onChange: async () => {},
    onFocus: async () => {},
    onBlur: async () => {},
    onSubmit: async () => {},
  }),
  values: {},
  deepValues: {},
  setValue: () => {},
  setAutocomplete: () => {},
  autocompleteOptions: {},
  validateValues: async () => {},
  touched: {},
  errors: {},
  activeInput: null,
});

const FormContextButtonContext = createContext<FormContextButtonInterface>({
  onClick: async () => {},
  disabled: false,
  clearForm: () => {},
});

/**
 * Access the form context
 */
export function useForm() {
  return useContext(FormContextContext);
}

/**
 * Access the form button context
 */
export function useFormButton() {
  return useContext(FormContextButtonContext);
}

/**
 * Provide the form context to children using the useForm hook such as the ones shown in the example, but also custom components if necessary.
 */
export function FormContext({
  children,
  initialValues,
  validate,
  onChange,
  onSubmit,
  onFormContext,
  submitUntouched = false,
  submitOnlyValidation = false,
}: FormContextProps) {
  const [values, setValues, valuesRef] = useStateRef<{ [key: string]: any }>(flattenObject(initialValues));
  const [touched, setTouchedInternal] = useState<{ [key: string]: TouchedState }>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [autocompleteOptions, setAutocompleteOptions] = useState<{ [key: string]: AutocompleteOption[] }>({});
  const [activeInput, setActiveInput] = useState<string | null>(null);

  // initial validation
  useEffect(() => {
    validateValues();
  }, []);

  const setTouched = (id: string, state: TouchedState) => {
    setTouchedInternal(c => ({ ...c, [id]: state }));
  };

  const setValue = async (id: string, value: any, touchedState: TouchedState = undefined): Promise<any> => {
    const currentValues = unflattenObject(clone(valuesRef.current));
    const subtree = id.split('.').reduce((s, e, i, arr) => {
      if (i === arr.length - 1) {
        s[e] = value;
      }
      return s[e];
    }, currentValues);
    setValues(flattenObject(currentValues));

    if (touchedState) {
      if (typeof subtree === 'object' && subtree !== null) {
        Object.keys(flattenObject(subtree)).forEach(key => setTouched(`${id}.${key}`, touchedState));
      } else {
        setTouched(id, touchedState);
      }
    }
    return currentValues;
  };

  const setAutocomplete = (id: string, options: AutocompleteOption[]) => {
    setAutocompleteOptions(c => ({ ...c, [id]: options }));
  };

  const validateValues = async (newValues?: { [key: string]: any }) => {
    if (validate) {
      const validationResult = await validate(unflattenObject(newValues ?? valuesRef.current), value);
      const validationErrors = flattenObject(validationResult);
      setErrors(flattenObject(validationResult));
      return validationErrors;
    }
  };

  const submitValues = async () => {
    if (submitOnlyValidation && Object.values((await validateValues()) ?? {}).some(e => e !== '')) {
      return;
    }
    onSubmit?.(unflattenObject(valuesRef.current), value);
  };

  const inputHandlers = (id: string): InputHandlers => ({
    value: values[id],
    onChange: async (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      customValue: any,
    ) => {
      const newValue = customValue ?? e.target.value;
      const newValues = await setValue(id, newValue);
      const autocompleteOptions = await onChange?.(unflattenObject(newValues), id, value);
      if (autocompleteOptions) {
        setAutocomplete(id, autocompleteOptions);
      }
      if (!submitOnlyValidation) {
        await validateValues();
      }
    },
    onFocus: async () => {
      setActiveInput(id);
    },
    onBlur: async () => {
      // delay these actions as otherwise the resulting rerender might cancel other events
      setTimeout(async () => {
        setActiveInput(null);
        setTouched(id, 'user');
        if (!submitOnlyValidation) {
          await validateValues();
        }
      }, 100);
    },
    onSubmit: submitValues,
  });

  const clearForm = () => {
    setValues({ ...flattenObject(initialValues) });
    setTouchedInternal({});
    setErrors({});
    setAutocompleteOptions({});
    setActiveInput(null);
  };

  const value = useMemo(
    () =>
      ({
        inputHandlers,
        values,
        get deepValues() {
          return unflattenObject(values);
        },
        setValue,
        setAutocomplete,
        autocompleteOptions,
        validateValues,
        touched,
        errors,
        activeInput,
      }) as FormContextInterface,
    [values, touched, errors, activeInput],
  );

  const valueButton = useMemo(
    () => ({
      onClick: submitValues,
      disabled:
        (!submitUntouched && Object.values(touched).filter(e => e).length === 0) ||
        (!submitOnlyValidation && Object.values(errors).some(e => e !== '')),
      clearForm,
    }),
    [submitUntouched, touched, submitOnlyValidation, errors, onSubmit],
  );

  useEffect(() => {
    onFormContext?.(value);
  }, [values, touched, errors, activeInput]);

  return (
    <FormContextContext.Provider value={value}>
      <FormContextButtonContext.Provider value={valueButton}>{children}</FormContextButtonContext.Provider>
    </FormContextContext.Provider>
  );
}

export function flattenObject(obj: any, prefix = '') {
  return Object.keys(obj).reduce(
    (acc, key) => {
      const pre = prefix.length ? `${prefix}.` : '';
      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        (!Array.isArray(obj[key]) || obj[key].length > 0) &&
        !(obj[key] instanceof Blob)
      ) {
        // do not flatten null, Blobs or empty arrays
        Object.assign(acc, flattenObject(obj[key], `${pre}${key}`));
      } else {
        acc[`${pre}${key}`] = obj[key];
      }
      return acc;
    },
    {} as { [key: string]: any },
  );
}

export function unflattenObject(obj: { [key: string]: any }) {
  const result: { [key: string]: any } = {};
  for (const key in obj) {
    key.split('.').reduce((s, e, i, arr) => {
      if (i === arr.length - 1) {
        s[e] = obj[key];
      } else if (!s[e]) {
        s[e] = isNaN(parseInt(arr[i + 1])) ? {} : []; // add array for numerical keys
      }
      return s[e];
    }, result);
  }
  return result;
}

export function resolveKey(obj: any, key: string) {
  return key.split('.').reduce((s, e) => s?.[e], obj);
}

function clone(obj: any) {
  const clone = rfdcClone(obj);
  for (const key in obj) {
    if (obj[key] instanceof Blob) {
      clone[key] = obj[key];
    }
  }
  return clone;
}
