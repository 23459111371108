import React, { createContext, ReactNode, useContext, useRef } from 'react';
import { LocationProvider, useLocation } from '@reach/router';
import { navigate } from 'gatsby';

export interface URLContext {
  location: Location;
  queryParams: { [key: string]: string };
  deleteQueryParam: (param: string) => void;
}

const URLContextContext = createContext<URLContext>({
  location: {
    ancestorOrigins: [] as unknown as DOMStringList,
    hash: '',
    host: '',
    hostname: '',
    href: '',
    origin: '',
    pathname: '',
    port: '',
    protocol: '',
    search: '',
    assign: () => {},
    reload: () => {},
    replace: () => {},
  },
  queryParams: {},
  deleteQueryParam: async () => {},
});

export function useURL() {
  return useContext(URLContextContext);
}

export function URLProvider({ children }: { children: ReactNode }) {
  return (
    <LocationProvider>
      <InnerURLProvider>{children}</InnerURLProvider>
    </LocationProvider>
  );
}

function InnerURLProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const currentUrlRef = useRef<string>(''); // needed to store state between renders
  currentUrlRef.current = location.href;

  const deleteQueryParam = async (param: string) => {
    const currentUrl = new URL(currentUrlRef.current);
    currentUrl.searchParams.delete(param);
    currentUrlRef.current = currentUrl.href;
    await navigate((currentUrl.pathname + currentUrl.search + currentUrl.hash) as any, { replace: true } as any);
  };

  return (
    <URLContextContext.Provider
      value={{
        location,
        queryParams: Object.fromEntries(new URLSearchParams(location.search).entries()),
        deleteQueryParam,
      }}
    >
      {children}
    </URLContextContext.Provider>
  );
}
