import React, { ReactNode } from 'react';
import {
  HintContent as SherpaHintContent,
  HintContentDescription as SherpaHintContentDescription,
  Hint as SherpaHint,
} from '../../../../deps/@bshgroup/component-hint';
import { Icon } from '../../Icon/Icon';

export type HintLevel = 'error' | 'warning' | 'info' | 'highlight' | 'success';

export interface HintProps {
  /**
   * Hint text
   */
  description: string | ReactNode;
  /**
   * Hint display level
   */
  level?: HintLevel;
  /**
   * Custom icon
   */
  icon?: any;
}

const styles: { [key in HintLevel]: { color?: any; hint: any; icon?: string } } = {
  error: {
    color: { color: 'rgba(190, 0, 4, 1)' },
    hint: { backgroundColor: 'rgba(190, 0, 4, 0.1)', marginTop: '-0.5rem' },
    icon: 'alert-error',
  },
  warning: {
    hint: { backgroundColor: 'rgb(255, 223, 149)' },
    icon: 'alert-warning',
  },
  info: {
    hint: { backgroundColor: 'rgb(209, 228, 255)' },
    icon: 'alert-info',
  },
  highlight: {
    hint: { backgroundColor: 'rgb(209, 228, 255)' },
  },
  success: {
    hint: { backgroundColor: 'rgba(184, 239, 201)' },
    icon: 'alert-success',
  },
};

/**
 * The `Hint` component is used to display a hint with markup defined by the hint level.
 */
export function Hint({
  description,
  level,
  icon,
  ...props
}: HintProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof HintProps>) {
  return (
    <SherpaHint
      css={level ? styles[level].hint : null}
      content={config => (
        <SherpaHintContent
          {...config}
          icon={() =>
            icon || (level && styles[level].icon) ? (
              <Icon
                value={icon || (level && styles[level].icon)}
                size="lg"
                color={level ? styles[level].color : null}
              />
            ) : (
              (undefined as any)
            )
          }
          description={config => (
            <SherpaHintContentDescription {...config} css={level ? styles[level].color : null}>
              {description}
            </SherpaHintContentDescription>
          )}
        ></SherpaHintContent>
      )}
      {...(props as any)}
    />
  );
}
