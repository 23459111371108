import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { domAnimation, LazyMotion } from 'framer-motion';
import { Motion as SherpaMotion } from '../../../../deps/@bshgroup/component-motion';
import { breakpoints } from '../../../global/global-config';
import {
  Navigation as SherpaNavigation,
  NavigationBar as SherpaNavigationBar,
  NavigationBarBrandArea as SherpaNavigationBarBrandArea,
  NavigationBarBrandAreaTagline as SherpaNavigationBarBrandAreaTagline,
} from '../../../../deps/@bshgroup/component-navigation';
import { Container } from '../Container/Container';
import { Logo as SherpaLogo } from '../../../../deps/@bshgroup/component-logo';
import { useHeader } from '../../../contexts/HeaderContext/HeaderContext';
import { useTranslation } from '../../../contexts/TranslationContext/TranslationContext';
import { Brandline } from '../Brandline/Brandline';

export interface MenuItem {
  headline: string;
  active: boolean;
  id: number;
  href: string;
}

export interface NavigationHeaderProps {
  /**
   * The title of the page
   */
  title: ReactNode;
  /**
   * Navigation links and actions
   */
  children?: ReactNode;
  /**
   * image component to be used instead of the default 'img' tag
   */
  imageComponent?: any;
  /**
   * link component to be used instead of the default 'a' tag
   */
  linkComponent?: any;
}

export function NavigationHeader({
  title,
  children,
  imageComponent,
  linkComponent,
  ...props
}: NavigationHeaderProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof NavigationHeaderProps>) {
  const { t } = useTranslation();
  const { setHeaderHeight, ignoreNextScrollRef } = useHeader();
  const [headerTop, setHeaderTop] = useState<number>(0);
  const headerRef = useRef<HTMLElement | null>(null);
  const showHeader = useRef(true);
  const headerHeight = useRef(0);
  const lastScrollPos = useRef(0);
  const lastWindowWidth = useRef([0, 0]);

  const ImageComponent = imageComponent || 'img';
  const LinkComponent = linkComponent || 'a';

  function handleNotResized(scrolledMoreThanTen: boolean) {
    if (scrolledMoreThanTen) {
      headerHeight.current = headerRef.current?.offsetHeight ?? 0;
      if (lastScrollPos.current > window.scrollY) {
        if (!showHeader.current) {
          showHeader.current = true;
          setHeaderTop(0);
          setHeaderHeight(headerHeight.current);
        }
      } else {
        if (showHeader.current) {
          showHeader.current = false;
          setHeaderTop(-headerHeight.current);
          setHeaderHeight(0);
        }
      }
      lastScrollPos.current = window.scrollY;
    }
  }

  function handleResized() {
    lastScrollPos.current = window.scrollY;
    lastWindowWidth.current = [lastWindowWidth.current[1], window.innerWidth];
    // header size changes on resize, update accordingly
    if (headerRef.current && headerHeight.current !== headerRef.current.offsetHeight) {
      headerHeight.current = headerRef.current.offsetHeight;
      if (showHeader.current) {
        setHeaderHeight(headerHeight.current);
      } else {
        setHeaderTop(-headerHeight.current);
      }
    }
  }

  useEffect(() => {
    if (window) {
      lastScrollPos.current = window.scrollY;
      window.addEventListener('scroll', () => {
        if (ignoreNextScrollRef.current) {
          return;
        }
        // make sure scrolling did not happen due to window resize (twice for debouncing)
        const wasNotResized =
          lastWindowWidth.current[0] === window.innerWidth && lastWindowWidth.current[1] === window.innerWidth;
        if (wasNotResized) {
          handleNotResized(Math.abs(lastScrollPos.current - window.scrollY) > 10);
        } else {
          handleResized();
        }
      });
      window.addEventListener('scrollend', () => {
        if (ignoreNextScrollRef.current) {
          ignoreNextScrollRef.current = false;
        }
      });
    }
  }, []);

  return (
    <LazyMotion features={domAnimation}>
      <SherpaMotion
        animate={{
          top: headerTop + 'px',
        }}
        initial={false}
        css={{
          position: 'sticky',
          zIndex: 50,
          backgroundColor: 'white',
        }}
        ref={headerRef}
        as="header"
        {...(props as any)}
      >
        <Brandline imageComponent={imageComponent} />
        <Container>
          <SherpaNavigation
            size={{
              [breakpoints.sm]: 'sm',
              [breakpoints.md]: 'md',
              [breakpoints.lg]: 'lg',
            }}
            css={{ '& > div': { border: 'none', paddingLeft: 0, paddingRight: 0 } }}
            variant="default"
            background="solid"
            brandBar={() => <></>}
            bar={config => (
              <SherpaNavigationBar
                {...config}
                css={{ '& > div': { width: '100%', overflow: 'hidden' } }}
                brandArea={config => (
                  <SherpaNavigationBarBrandArea
                    {...config}
                    logo={config => (
                      <LinkComponent
                        to="/"
                        css={{ cursor: 'pointer' }}
                        aria-label={t('DSSF.Components.Core.NavigationHeader.HomeLink')}
                      >
                        <SherpaLogo
                          {...config}
                          src="/assets/images/bosch.png"
                          as={ImageComponent}
                          loading="eager"
                          fadeIn={false}
                        />
                      </LinkComponent>
                    )}
                    tagline={config => (
                      <SherpaNavigationBarBrandAreaTagline css={{overflow: 'hidden'}} {...config}>{title}</SherpaNavigationBarBrandAreaTagline>
                    )}
                    css={{ '& > div': {overflow: 'hidden'}, '& > div > div': { gap: '2rem' } }}
                  />
                )}
                actions={() => children as ReactElement}
              />
            )}
          />
        </Container>
      </SherpaMotion>
    </LazyMotion>
  );
}
