import React, { ReactNode } from 'react';
import AutoOrientationService, { Orientation } from '../../../services/autoOrientation';
import { Spacer } from '../../Spacer/Spacer';
import { CSSObject } from '@emotion/react';
import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';

export interface TeaserProps {
  /**
   * Product title
   */
  title?: ReactNode;
  /**
   * The description of the conversion teaser
   */
  description?: ReactNode;
  /**
   * Image path or more complex image component
   */
  image?: ReactNode;
  /**
   * Additional components buttons
   */
  children?: React.ReactNode;
  /**
   * Orientation for alignment of image and text components
   */
  orientation?: Orientation;
  /**
   * Alignment of image and text components
   */
  mediaAlignment?: 'start' | 'end';
}

/**
 * The `Teaser` displays an image with a title, description and action items.
 */
export function Teaser({
  title,
  description,
  image,
  children,
  orientation = 'auto',
  mediaAlignment = 'start',
  ...props
}: TeaserProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof TeaserProps>) {
  return (
    <div
      css={
        {
          display: 'flex',
          gap: '2rem',
          ...AutoOrientationService.getStyles(
            {
              flexDirection: 'column',
            },
            {
              flexDirection: mediaAlignment === 'start' ? 'row' : 'row-reverse',
              alignItems: 'center',
            },
            orientation,
          ),
        } as CSSObject
      }
      {...props}
    >
      <div css={{ margin: '16px 0', flex: 1 }}>
        {image && typeof image === 'string' ? <img src={image} alt="" /> : image}
      </div>
      <div css={{ flex: 1 }}>
        <Heading style="title">{title}</Heading>
        {description && (
          <>
            <Spacer size="m" />
            <Text>{description}</Text>
          </>
        )}
        {description && children && <Spacer size="l" />}
        {children}
      </div>
    </div>
  );
}
