import React, { ReactNode } from 'react';
import { Container as SherpaContainer } from '../../../../deps/@bshgroup/component-container';
import { breakpoints } from '../../../global/global-config';

export interface ContainerProps {
  /**
   * Container content
   */
  children: ReactNode;
  /**
   * HTML element used for the container, div by default
   */
  as?: React.ElementType;
}

/**
 * The `Container` component is used to wrap content and apply padding and max-width.
 */
export function Container({
  children,
  as,
  ...props
}: ContainerProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof ContainerProps>) {
  return (
    <SherpaContainer
      as={as as 'div'}
      space={{
        [breakpoints.sm]: 'small-sm',
        [breakpoints.md]: 'small-lg',
        [breakpoints.lg]: 'large-sm',
        [breakpoints.xl]: 'large-md',
      }}
      maxWidth={{
        [breakpoints.sm]: breakpoints.sm,
        [breakpoints.md]: breakpoints.md,
        [breakpoints.lg]: breakpoints.lg,
        [breakpoints.xl]: breakpoints.xl,
      }}
      leftInset={true}
      rightInset={true}
      {...props}
    >
      {children}
    </SherpaContainer>
  );
}
