import React, { createContext, useContext, useMemo, useRef, useState } from 'react';

export interface HeaderContextInterface {
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
  ignoreNextScrollRef: { current: boolean };
}

const HeaderContextContext = createContext<HeaderContextInterface>({
  headerHeight: 0,
  setHeaderHeight: () => {},
  ignoreNextScrollRef: { current: false },
});

/**
 * Hook to access the HeaderContext
 */
export function useHeader() {
  return useContext(HeaderContextContext);
}

/**
 * Provider for the `HeaderContext` which contains the header height state as well as a ref to set if the next scroll event should be ignored by the header
 */
export function HeaderContext({ children }: { children: React.ReactElement }) {
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const ignoreNextScrollRef = useRef<boolean>(false);

  const value = useMemo(() => {
    return { headerHeight, setHeaderHeight, ignoreNextScrollRef };
  }, [headerHeight, ignoreNextScrollRef]);

  return <HeaderContextContext.Provider value={value}>{children}</HeaderContextContext.Provider>;
}
