import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface AccordionControlContext {
  isExpanded: (id: string) => boolean;
  setExpanded: (id: string, state: boolean) => void;
  toggleExpanded: (id: string) => void;
}

const AccordionControlContextContext = createContext<AccordionControlContext>({
  isExpanded: () => false,
  setExpanded: () => {},
  toggleExpanded: () => {},
});

export function useAccordionControl() {
  return useContext(AccordionControlContextContext);
}

export function AccordionControlProvider({ children, single = false }: { children: ReactNode; single?: boolean }) {
  const [expandedList, setExpandedList] = useState<{ [id: string]: boolean | undefined }>({});

  const isExpanded = (id: string) => !!expandedList[id];

  const setExpanded = (id: string, value: boolean) => {
    if (single) {
      setExpandedList({ [id]: value });
    } else {
      setExpandedList(c => ({ ...c, [id]: value }));
    }
  };

  const toggleExpanded = (id: string) => setExpanded(id, !isExpanded(id));

  return (
    <AccordionControlContextContext.Provider value={{ isExpanded, setExpanded, toggleExpanded }}>
      {children}
    </AccordionControlContextContext.Provider>
  );
}
