import React, {KeyboardEvent, useEffect, useRef} from 'react';
import { useTokens } from '../../../../deps/@bshgroup/component-themes';
import { useForm } from '../../context/FormContext/FormContext';
import { Hint } from '../Hint/Hint';

export interface FormTextareaProps {
  /**
   * The id of the textarea, needs to be the name of the property of the form
   */
  id: string;
  /**
   * The label of the textarea
   */
  label?: string;
  /**
   * The placeholder of the textarea
   */
  placeholder?: string;
  /**
   * Whether the input value is required
   */
  required?: boolean;
  /**
   * Messages to be displayed when the input validation shows an error
   */
  messages?: { [key: string]: string };
  /**
   * Whether the textarea is disabled
   */
  disabled?: boolean;
  /**
   * The height of the textarea in rem
   */
  height?: number;
  /**
   * Whether the form should be submitted when the user presses enter inside the field
   */
  submitOnEnter?: boolean;
}

/**
 * A textarea component to be used inside a `FormContext`
 */
export function FormTextarea({
  id,
  label,
  placeholder,
  required,
  messages = {},
  disabled,
  height,
  submitOnEnter = false,
  ...props
}: FormTextareaProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof FormTextareaProps>) {
  const tokens = useTokens();
  const { inputHandlers, touched, errors } = useForm();

  // React uses focusout events for the onBlur event, which are not triggered by autofill, so we have to listen for real onblur events manually
  const inputRef = useRef<any>();
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('blur', handleOnBlur);
    }
  }, [inputRef]);

  const handleOnBlur = () => {
    inputHandlers(id).onBlur();
  };

  const handleOnKeyUp=(e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (submitOnEnter && e.key === 'Enter' && !e.shiftKey) {
      inputHandlers(id).onSubmit();
    }
  };

  return (
    <div
      {...props}
      css={{
        background: tokens.styles['textArea-base-idle-initial-background'],
        fontFamily: tokens.styles['textArea-content-label-initial-fontFamily'],
        fontSize: tokens.styles['textArea-content-label-initial-fontSize'],
        fontWeight: tokens.styles['textArea-content-label-initial-fontWeight'],
        padding: '0.75rem',
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {label && (
        <label htmlFor={id}>
          {label}
          {required && ' *'}
        </label>
      )}
      <textarea
        id={id}
        {...inputHandlers(id)}
        onKeyUp={handleOnKeyUp}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        css={{
          flex: 1,
          border: 'none',
          resize: 'none',
          background: 'inherit',
          fontFamily: 'inherit',
          fontSize: '1rem',
          fontWeight: 'inherit',
          width: '100%',
          height: `${height ? height : 0}rem`,
        }}
        aria-describedby={`${id}-error`}
      />
      {touched[id] && errors[id] && (
        <Hint id={`${id}-error`} level="error" description={messages?.[errors[id]]} aria-live="polite" />
      )}
    </div>
  );
}
