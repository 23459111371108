import React, { ReactNode, JSX } from 'react';
import { breakpoints } from '../../../global/global-config';
import {
  Accordion as SherpaAccordion,
  AccordionItemDivider as SherpaAccordionItemDivider,
  AccordionItemProps as SherpaAccordionItemProps,
} from '../../../../deps/@bshgroup/component-accordion';
import { AccordionControlProvider } from './AccordionControlContext';

export interface AccordionProps {
  /**
   * Only have one item opened at a time
   */
  single?: boolean;
  /**
   * Accordion items
   */
  children: ReactNode;
}

/**
 * The `Accordion` displays a list of high-level options that can expand/collapse to reveal more information.
 */
export function Accordion({
  single = false,
  children,
  ...props
}: AccordionProps & Omit<Partial<React.HTMLAttributes<HTMLDivElement>>, keyof AccordionProps>) {
  return (
    <AccordionControlProvider single={single}>
      <SherpaAccordion
        size={{
          [breakpoints.sm]: 'sm',
          [breakpoints.md]: 'md',
        }}
        items={
          React.Children.map(children, child => {
            return () => child;
          }) as ((config: Pick<SherpaAccordionItemProps, 'size'>) => JSX.Element)[]
        }
        divider={config => <SherpaAccordionItemDivider {...config} />}
        {...props}
      />
    </AccordionControlProvider>
  );
}
