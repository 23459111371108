import React, { ReactNode } from 'react';
import { BoschAuthProvider, ThemeProvider } from '@dssf/component-library';
import { URLProvider } from './src/contexts/url';

export const scopes = ['openid', 'profile', 'offline_access', 'api://ai-usecases-ui.bosch.com/app'];

export function wrapRootElement({ element }: { element: ReactNode }) {
  return (
    <BoschAuthProvider
      clientId={process.env.GATSBY_BOSCH_AUTH_CLIENT_ID!}
      redirectUri={location => process.env.GATSBY_BOSCH_AUTH_REDIRECT_URI!.replace('{host}', location?.hostname ?? '')}
      authority={`https://login.microsoftonline.com/${process.env.GATSBY_BOSCH_AUTH_TENANT_ID}`}
      errorComponent={<h1>Login failed</h1>}
      scopes={scopes}
    >
      <URLProvider>
        <ThemeProvider>{element}</ThemeProvider>
      </URLProvider>
    </BoschAuthProvider>
  );
}
