import React, { ReactNode } from 'react';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

export interface BoschAuthProviderProps {
  children: ReactNode;
  clientId: string;
  redirectUri: (location: Location) => string;
  authority: string;
  errorComponent: ReactNode;
  scopes: string[];
}

export function BoschAuthProvider({
  children,
  clientId,
  redirectUri,
  authority,
  errorComponent,
  scopes,
}: BoschAuthProviderProps): ReactNode {
  if (clientId) {
    if (typeof location !== 'undefined') {
      const pca = new PublicClientApplication({
        auth: {
          clientId: clientId,
          redirectUri: redirectUri(location),
          authority: authority,
        },
      });

      return (
        <MsalProvider instance={pca}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ scopes: scopes }}
            errorComponent={() => errorComponent}
          >
            {children}
          </MsalAuthenticationTemplate>
        </MsalProvider>
      );
    } else {
      return <div></div>; // this basically removes all benefits of SSG, but this is expected when hiding the content behind authentication
    }
  } else {
    // auth disabled
    return children;
  }
}
